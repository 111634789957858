import React from 'react';
import styled from 'styled-components';
import PageTransition from 'gatsby-plugin-page-transitions';

import { AppearText, AppearImage } from '../components/common/appear';
import P from '../components/common/p';

const Page = styled.div`
  min-height: 100vh;
  background: #FFF;
  padding-top: 241px;
  max-width: 1241px;
  margin: 0 auto;
  padding: 0 135px;
  padding-top: 241px;

  width: 100%;
  max-width: 1620px;
  @media (max-width: 1600px) {
    max-width: 1241px;
    padding: 0 104px;
    padding-top: 241px;
  }
  @media (max-width: 1200px) {
    padding: 0 57px;
    padding-top: 241px;
    min-height: auto;
  }

  @media (max-width: 600px) {
      padding: 0 15px;
      padding-top: 180px;
  }
`;

const H1 = styled.h1`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 92px;
    line-height: 80px;
    color: #0064BA;
    /* or 87% */
    @media (max-width: 1200px) {
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 80px;
    }

    @media (max-width: 600px) {
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 54px;
    }
`;
const NotFoundPage = () => (
  <PageTransition>
    <>
      <Page>
        <H1><AppearText>
            404, Not found
          </AppearText></H1>
        <P>
          <AppearText>
            You just hit a route that does not exist...
          </AppearText>
        </P>
      </Page>
    </>
  </PageTransition>
);

export default NotFoundPage;
